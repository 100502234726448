<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-b">项目管理</span>
      <div class="a-flex-rcc">
        <le-export-btn v-if="false" :page-param="pageParam"></le-export-btn>
        <el-button
          icon="el-icon-plus"
          type="primary"
          class="s-btn-add a-ml-24 myblue"
          @click="handlerAddProject"
        >
          项目
        </el-button>
      </div>
    </div>
    <el-card class="el-main">
      <le-search-form id="leSearch" @reset="handlerRest" @search="handlerSearch">
        <le-input-icon-search
          v-model="pageParam.params.searchKey"
          label="项目名称"
          placeholder="请输入项目/站点名称"
        />
        <le-select-local-search
          v-model="pageParam.params.searchStatus"
          label="项目状态"
          :options="optionsProject"
        />
        <le-select-num-range
          label="我方收益"
          :min-data.sync="pageParam.params.minRate"
          :max-data.sync="pageParam.params.maxRate"
          :options="optionsShouyi"
          unit="%"
        ></le-select-num-range>
      </le-search-form>

      <le-pagview :page-param="pageParam" @setData="setTableData">
        <el-table
          v-sticky="{ top: 0, parent: '.el-card__body' }"
          :data="tableData"
          :highlight-current-row="true"
          style="width: 100%"
        >
          <el-table-column prop="name" label="项目名称"></el-table-column>
          <el-table-column prop="companyName" label="所属商户"></el-table-column>
          <el-table-column label="站点数量/设备数量">
            <template slot-scope="{ row }">
              <span>{{ row.stationSize }}/{{ row.deviceNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="我方占比">
            <template slot-scope="{ row }">
              <span>
                {{
                  row.maxRate == row.minRate
                    ? row.maxRate / 100 + "%"
                    : row.minRate / 100 + "% ~ " + row.maxRate / 100 + "%"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="本月收益(元)">
            <template slot-scope="{ row }">
              <span>{{ util.numFormat(row.monthOrderIncome) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="总收益(元)">
            <template slot-scope="{ row }">
              <span>{{ util.numFormat(row.hisOrderIncome) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="statusText" label="状态"></el-table-column>
          <el-table-column prop="createTimeText" label="签订日期"></el-table-column>
          <el-table-column prop="runDays" label="运行天数"></el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="{ row }">
              <div class="a-flex-rcc">
                <div class="a-cursor-p" @click="handlerGoDetail(row.id)">
                  <el-tooltip class="item" effect="dark" content="项目详情" placement="top">
                    <img src="../../assets/icon/option-detail.png" class="a-wh-16" />
                  </el-tooltip>
                </div>
                <div class="a-cursor-p a-ml-16" @click="handlerEditPro(row.id)">
                  <el-tooltip class="item" effect="dark" content="编辑项目" placement="top">
                    <img src="../../assets/icon/option-edit.png" class="a-wh-16" />
                  </el-tooltip>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </le-pagview>
    </el-card>
  </div>
</template>

<script>
import util from "../../utils/util.js";
export default {
  name: "ProjectList",

  data() {
    return {
      util: util,
      selectedOptions: [],
      tableData: [],
      optionsProject: [
        {
          label: "服务中",
          value: 1,
        },
        {
          label: "未生效",
          value: 2,
        },
        {
          label: "已失效",
          value: 3,
        },
      ],
      optionsShouyi: [
        {
          minData: 5,
          maxData: 30,
        },
        {
          minData: 31,
          maxData: 60,
        },
        {
          minData: 61,
          maxData: 95,
        },
      ],
      pageParam: null,
    };
  },
  created() {
    this.pageParam = {
      url: this.$Config.apiUrl.getIncomeStatInfoList,
      method: "post",
      params: {
        searchKey: "",
        minRate: "",
        maxRate: "",
        searchStatus: "",
      },
      freshCtrl: 1,
    };
  },

  methods: {
    setTableData(data) {
      this.tableData = data;
    },
    handlerRest() {
      this.pageParam.params = {
        searchKey: "",
        minRate: "",
        maxRate: "",
        searchStatus: "",
      };
      this.handlerSearch();
    },
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    handlerAddProject() {
      this.$router.push({
        path: "/project/project-add",
      });
    },
    handlerEditPro(id) {
      this.$router.push({
        path: "/project/project-edit",
        query: {
          incomeId: id,
        },
      });
    },
    handlerGoDetail(id) {
      this.$router.push({
        path: "/project/project-detail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
